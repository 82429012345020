<!-- <template>
  <v-main>
    <v-container>
      <h3 class="mb-5 mt-5">Create Question</h3>
      <section v-if="errored">
        <p>
          We're sorry, we're not able to retrieve this information at the
          moment, please try back later
        </p>
      </section>
      <v-btn @click="goBackToQuestionList">Back to Question List</v-btn>
      <div v-if="loading">Loading...</div>
      <v-radio-group v-model="question.question_type" row>
        <template v-slot:label>
          <div>Question Types</div>
        </template>
        <div v-for="stat in questionTypes" :key="stat">
          <v-radio dense :value="stat.value" :label="stat.name"
            :active-class="stat == stat.value ? 'v-item--active' : ''"
          >
          </v-radio>
        </div>
      </v-radio-group>
      <v-textarea v-model="question.question_text" required>
        <template v-slot:label>
          <div>Question Text</div>
        </template>
      </v-textarea>
      <v-textarea v-model="question.question_content" required>
        <template v-slot:label>
          <div>Question Contents</div>
        </template>
      </v-textarea>
      <v-text-field
      required
        v-model="question.limitTimeSecond"
        placeholder="Limited Time In Second"
        ><template v-slot:label>
          <div>Limited Time In Second</div>
        </template></v-text-field
      >
      <v-radio-group v-model="question.answer" row required>
        <div v-for="stat in answerChoices" :key="stat">
          <v-radio dense :value="stat" :label="stat"> </v-radio>
        </div>
      </v-radio-group>
      <v-textarea v-model="question.answer_explanation" outlined>
        <template v-slot:label>
          <div>Answer Explanation</div>
        </template>
      </v-textarea>
      <v-btn @click="goBackToQuestionList">Back To Question List</v-btn>|<v-btn
        @click="createQuestion"
        >Create</v-btn
      >
    </v-container>
  </v-main>
</template> -->


<template>
  <v-main>
    <v-container>
      <h3 class="mb-5 mt-5">Create Question</h3>
      <section v-if="errored">
        <p>
          We're sorry, we're not able to retrieve this information at the
          moment, please try back later
        </p>
      </section>
      <v-btn @click="goBackToQuestionList" class="mb-5"
        >Back to Question List</v-btn
      >
      <div v-if="loading">Loading...</div>

      <v-row>
        <v-col>
          <v-select
            outlined
            :items="questionSetList"
            item-text="title"
            item-value="questionSetId"
            label="Question Set"
            v-model="question.questionSetId"
            required
          ></v-select>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field
            v-model="question.limitTimeSecond"
            placeholder="Limit Time Second"
            outlined
            type="number"
          >
            <template v-slot:label>
              <div>Limit Time Second</div>
            </template>
          </v-text-field>
        </v-col>
        <v-col>
          <v-radio-group v-model="question.questionType" row>
            <template v-slot:label>
              <div>Question Types</div>
            </template>
              <v-radio
              v-for="(stat,index) in questionTypes" :key="index"
                dense
                :value="stat.value"
                :label="stat.name"
                :active-class="stat == stat.value ? 'v-item--active' : ''"
              >
              </v-radio>
          </v-radio-group>
        </v-col>
        <v-col>
          <v-radio-group v-model="question.answer" row required>
            <template v-slot:label>
              <div>Set Answer</div>
            </template>
              <v-radio v-for="(stat,index) in answerChoices" :key="index"
                :value="stat.value"
                :label="stat.name"
                :active-class="stat == stat.value ? 'v-item--active' : ''"
              >
              </v-radio>
          </v-radio-group>
        </v-col>
      </v-row>
      <v-tabs
        v-model="languageTab"
        class="mb-5"
        background-color="deep-black"
        centered
        dark
        icons-and-text
      >
        <v-tab
          v-for="(i, index) in this.languageList"
          :key="index"
          :href="'#tab-' + i.value"
        >
          {{ i.name }}
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="languageTab">
        <v-tab-item
          v-for="(i, index) in this.languageList"
          :key="index"
          :value="`tab-${i.value}`"
          class="mt-5"
        >
          <v-text-field
            v-model="question.questionData[index].languageId"
            :v-text="i.value"
            v-show="false"
          />
          <v-text-field
            v-model="question.questionData[index].questionText"
            placeholder="Enter Question Text"
            outlined
            label="Question Text"
          >
          </v-text-field>
          <v-textarea
            v-model="question.questionData[index].answerExplanation"
            outlined
          >
            <template v-slot:label>
              <div>Answer Explanation</div>
            </template>
          </v-textarea>
        </v-tab-item>
      </v-tabs-items>
      <v-btn @click="goBackToQuestionList" class="mt-5"
        >Back To Question List</v-btn
      >&nbsp; &nbsp;<v-btn @click="createQuestion" class="mt-5">Create</v-btn>
    </v-container>
  </v-main>
</template>

<script>
// @ is an alias to /src
// import { mapGetters } from "vuex";
import { CREATE_QUESTION, FETCH_QUESTION_SET_LIST } from "../store/actions.type";
import { languageList } from "../common/ref_data";

export default {
  name: "CreateQuestion",
  data() {
    return {
      languageTab: "tab-1",
      answerChoices: [
        {
          name: "Yes",
          value: "yes",
        },
        {
          name: "No",
          value: "no",
        },
      ],
      questionTypes: [
        {
          name: "Yes/No",
          value: "yesno",
        },
      ],
      languageList: languageList,
      question: {
        questionSetId: null,
        questionType: "yesno",
        answer: "yes",
        limitTimeSecond: 5,
        questionData: [],
      },
      questionSetList: [],
      loading: true,
      errored: false,
    };
  },
  computed: {},
  mounted() {},
  created() {
    this.languageList = languageList;
    this.setQuestions();
    this.getQuestionSetList();
  },
  methods: {
    setQuestions() {
      for (let i = 0; i < this.languageList.length; i++) {
        this.question.questionData.push({
          languageId: this.languageList[i].value,
          questionText: "",
          questionContent: "",
          answerList: "",
          answerExplanation: "",
        });
      }
      console.log(this.question);
    },
    onChildUpdate(newValue) {
      this.value = newValue;
      console.log(this.value);
    },
    goBackToQuestionList() {
      this.$router.push({
        name: "QuestionList",
      });
    },
    getQuestionSetList() {
      this.$store
        .dispatch(FETCH_QUESTION_SET_LIST)
        .then((data) => {
          this.loading = false;
          if (data.length > 0) {
            this.questionSetList = data;
          }
          console.log(this.questionSetList);
        })
        .catch((response) => {
          console.log(response);
          this.loading = false;
          this.errored = true;
        })
        .finally(() => {
          this.loading = false;
          this.errored = false;
        });
    },
    resetQuestion() {
      this.question = {
        questionSetId: null,
        questionType: "yesno",
        answer: "yes",
        limitTimeSecond: 1,
        questionData: [],
      };
      this.setQuestions();
    },
    createQuestion() {
      if (
        this.question.questionSetId != null &&
        this.question.questionData.length > 0 &&
        this.question.limitTimeSecond != 0 &&
        this.question.questionData[0].questionText != "" &&
        this.question.questionData[0].answerExplanation != "" &&
        this.question.questionData[1].questionText != "" &&
        this.question.questionData[1].answerExplanation != ""
      ) {
        console.log(this.question);
        this.$store
          .dispatch(CREATE_QUESTION, { questionData: this.question })
          .then(() => {
            this.loading = false;
            this.resetQuestion();
            this.$swal.fire({
              title: "Question",
              text: "Successfully created!",
              icon: "success",
              position: "center",
              showConfirmButton: false,
              timer: 1500,
            });
            this.goBackToQuestionList();
          })
          .catch((response) => {
            console.log(response);
            this.loading = false;
            this.errored = true;
            this.$swal.fire({
              title: "Question",
              text: "Failed to create!",
              icon: "danger",
              position: "center",
              showConfirmButton: false,
              timer: 1500,
            });
          })
          .finally(() => (this.loading = false));
      }else{
        this.$swal.fire({
              title: "Question",
              text: "Please fill all fields",
              icon: "info",
              position: "center",
              showConfirmButton: false,
              timer: 1500,
            });
      }
    },
  },
};
</script>

